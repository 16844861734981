import { Paper } from "@mantine/core";
import "./PopupBox.scss";

interface PopupBoxProps {
  position: {
    x: number;
    y: number;
  };
  children: React.ReactNode;
  className?: string;
}

/** Base component for custom React Flow popup to provide consistent UI & NavLink styling */
function PopupBox({ position, children, className }: PopupBoxProps) {
  return (
    <Paper
      className={`PopupBox ${className}`}
      p="xs"
      shadow="xl"
      style={{ top: position.y, left: position.x }}
    >
      {children}
    </Paper>
  );
}

export { PopupBox };
