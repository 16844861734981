import { useMemo, useState } from "react";
import { Collapse } from "reactstrap";
import { useIsMieterstrom } from "../../../../../../hooks/useIsMieterstrom";
import urls from "../../../../../../urls";
import { ObjectName } from "../../../../../../utils/enums";
import { Icon } from "../../../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../../../BuildingBlocks/Icon/types";
import { AddressFields } from "../../../../../DynamicForm/FormItems/AddressFields/AddressFields";
import { ChangeableOperatorField } from "../../../../../DynamicForm/FormItems/FormField/ChangeableOperatorField/ChangeableOperatorField";
import { DynamicChoiceFields } from "../../../../../DynamicForm/FormItems/FormField/DynamicChoiceFields/DynamicChoiceFields";
import { DynamicCustomChoiceFields } from "../../../../../DynamicForm/FormItems/FormField/DynamicCustomChoiceField/DynamicCustomChoiceField";
import { FieldWithInfoTextAbove } from "../../../../../DynamicForm/FormItems/FormField/FieldWithInfoTextAbove/FieldWithInfoTextAbove";
import { GeneralPartnerFields } from "../../../../../DynamicForm/FormItems/FormField/GeneralPartnerFields/GeneralPartnerFields";
import { RepresentationFields } from "../../../../../DynamicForm/FormItems/FormField/RepresentationFields/RepresentationFields";
import { RightAlignedField } from "../../../../../DynamicForm/FormItems/FormField/RightAlignedField/RightAlignedField";
import type { FormItemsProps } from "../../../../../DynamicForm/FormItems/FormItems";
import FormItems from "../../../../../DynamicForm/FormItems/FormItems";
import { UploadableImageField } from "../../../../../DynamicForm/FormItems/UploadableImageField";
import { withCombinedFields } from "../../../../../DynamicForm/FormItems/withCombinedFields";
import { withCreatableDropdown } from "../../../../../DynamicForm/FormItems/withCreatableDropdown";
import { withDependentDaterange } from "../../../../../DynamicForm/FormItems/withDependentDaterange";
import { withLoadprofileFields } from "../../../../../DynamicForm/FormItems/withLoadprofileFields";
import { IconHelpText } from "../../../../../IconHelpText/IconHelpText";
import {
  MARKET_LOCATION_FORM_FIELD_NAMES,
  METERING_LOCATION_FORM_FIELD_NAMES,
  formFieldNames,
  getCustomerFields
} from "../../../../../MeterWizard/meterMarketFormFields";
import { ParticipationFields } from "../../../../../ParticipationFields/ParticipationFields";
import { CREATE_FORM_FIELD_NAMES_REDUCED as PERSON_FORM_FIELD_NAMES } from "../../../../../PersonWizard/PersonWizard";
import { useShouldShowStaffView } from "../../../../../StaffViewToggle/useShouldShowStaffView";
import { VollbenutzungsstundenList } from "../../Components/VollbenutzungsstundenList/VollbenutzungsstundenList";
import {
  DEFAULT_FIELDS as DEFAULT_CONNECTION_FIELDS,
  FIELDS_FOR_MIETERSTROM_SITES
} from "../../Data/Connection";
import type { Form, FormSection } from "../../Data/data-types";
import {
  GENERATOR_STROMSTEUER_FIELDS,
  STROMSTEUER_FIELDS_ABOVE_TABLE
} from "../../Data/Generator";
import {
  GENERAL_FIELDS_PART_2,
  GENERAL_PARTNER_FIELDS
} from "../../Data/Person";

const FormItemsWithLoadprofileFields = withLoadprofileFields(FormItems);

type FormItemsComponentType =
  | typeof FormItems
  | Parameters<typeof withCombinedFields>[0];

interface SectionProps
  extends FormSection,
    Pick<Form, "extraProps">,
    Pick<FormItemsProps, "onInput"> {
  name: string;
  isHighlighted: (missingFields, exclusiveOrFields, formItem) => boolean;
  missingFields?: Array<string>;
  exclusiveOrFields?: Array<Array<string>>;
}

function Section({
  name,
  fields,
  values,
  header,
  errors,
  extraProps,
  isSubSection,
  isCollapsible,
  initialToggle,
  helpText,
  missingFields,
  exclusiveOrFields,
  onInput,
  isHighlighted
}: SectionProps) {
  const isStaff = useShouldShowStaffView();
  const siteId =
    typeof extraProps?.siteId === "number" ? extraProps.siteId : undefined;
  const { data: siteIsMieterstrom } = useIsMieterstrom(siteId);

  const meterlingLocationFormFieldNames = isStaff
    ? METERING_LOCATION_FORM_FIELD_NAMES
    : getCustomerFields(METERING_LOCATION_FORM_FIELD_NAMES);

  const marketLocationFormFieldNames = isStaff
    ? MARKET_LOCATION_FORM_FIELD_NAMES
    : getCustomerFields(MARKET_LOCATION_FORM_FIELD_NAMES);

  const [isOpen, setIsOpen] = useState(initialToggle);

  const CustomFormItemsComponent = useMemo(() => {
    let FormItemsComponent: FormItemsComponentType = FormItems;

    if (extraProps) {
      const { objectName, siteId, variantId } = extraProps;
      const includedFields = fields.map((field) => field.name);
      if (includedFields.includes("invoiceSuppliedAddressIsGeneralAddress")) {
        FormItemsComponent = withCombinedFields(
          FormItemsComponent,
          DynamicChoiceFields,
          [
            "invoiceSuppliedAddressIsGeneralAddress",
            "invoiceSuppliedAddress",
            "invoiceSuppliedAddressExtra",
            "invoiceSuppliedZip",
            "invoiceSuppliedCity"
          ],
          {
            extraProps: {
              choiceFieldKey: "invoiceSuppliedAddressIsGeneralAddress",
              fieldsKeyArray: [
                "invoiceSuppliedAddress",
                "invoiceSuppliedAddressExtra",
                "invoiceSuppliedZip",
                "invoiceSuppliedCity"
              ],
              missingFields: missingFields
            }
          }
        );
      }
      if (includedFields.includes("supplierAccordingToStromstg")) {
        FormItemsComponent = withCombinedFields(
          FormItemsComponent,
          DynamicCustomChoiceFields,
          ["supplierAccordingToStromstg", "supplierLicenseValidityDate"],
          {
            extraProps: {
              choiceFieldKey: "supplierAccordingToStromstg",
              keysToShow: ["full", "limited"],
              fieldsKeyArray: ["supplierLicenseValidityDate"],
              missingFields: missingFields
            }
          }
        );
      }

      if (includedFields.includes("unternehmenInSchwierigkeiten")) {
        FormItemsComponent = withCombinedFields(
          FormItemsComponent,
          FieldWithInfoTextAbove,
          ["unternehmenInSchwierigkeiten"],
          {
            extraProps: {
              infoText:
                "Prüfen Sie bitte zur Beantwortung dieser Frage intern Ihren Status ab. " +
                "Relevante Informationen sowie weiterführende Links finden Sie hier: " +
                "[Unternehmen in Schwierigkeiten](https://support.node.energy/knowledge/unternehmen-in-schwierigkeiten)",
              missingFields: missingFields
            }
          }
        );
      }
      if (includedFields.includes("taxFormsApplicantNumber")) {
        FormItemsComponent = withCombinedFields(
          FormItemsComponent,
          FieldWithInfoTextAbove,
          ["taxFormsApplicantNumber"],
          {
            extraProps: {
              infoText:
                "Die aufgeführten Informationen können unter Umständen die Kommunikation mit " +
                "dem HZA erleichtern, sind aber nicht in jedem Fall bekannt. Insofern Sie " +
                "die Informationen ganz oder teilweise haben, tragen Sie diese hier ein.",
              missingFields: missingFields
            }
          }
        );
      }
      if (includedFields.includes("taxFormsContactName")) {
        FormItemsComponent = withCombinedFields(
          FormItemsComponent,
          FieldWithInfoTextAbove,
          ["taxFormsContactName"],
          {
            extraProps: {
              infoText:
                "Das Hauptzollamt benötigt einen Ansprechpartner für Rückfragen. " +
                "Das können Sie selbst oder Ihr Steuerberater sein. Bitte geben Sie hier die Kontaktdaten ein.",
              missingFields: missingFields
            }
          }
        );
      }
      if (includedFields.includes("invoiceSupplierIsGbr")) {
        FormItemsComponent = withCombinedFields(
          FormItemsComponent,
          DynamicChoiceFields,
          [
            "invoiceSupplierIsGbr",
            "invoiceSupplierCourt",
            "invoiceSupplierRegisterNumber"
          ],
          {
            extraProps: {
              choiceFieldKey: "invoiceSupplierIsGbr",
              fieldsKeyArray: [
                "invoiceSupplierCourt",
                "invoiceSupplierRegisterNumber"
              ],
              exclusiveOrFields: exclusiveOrFields,
              missingFields: missingFields,
              isHighlighted: isHighlighted
            }
          }
        );
      }

      if (includedFields.includes("vollbenutzungsstunden")) {
        FormItemsComponent = withCombinedFields(
          FormItemsComponent,
          VollbenutzungsstundenList,
          ["vollbenutzungsstunden"]
        );
      }

      if (includedFields.includes("loadprofile")) {
        FormItemsComponent = FormItemsWithLoadprofileFields;
      }

      if (includedFields.includes("participationsInMieterstromModel")) {
        FormItemsComponent = withCombinedFields(
          FormItemsComponent,
          ParticipationFields,
          ["participationsInMieterstromModel"]
        );
      }

      if (includedFields.includes("person") && typeof variantId === "number") {
        if (
          objectName === ObjectName.Generator ||
          objectName === ObjectName.Consumer
        ) {
          FormItemsComponent = withCombinedFields(
            FormItemsComponent,
            ChangeableOperatorField,
            ["person"]
          );
        } else {
          const personNonFieldData = {
            variant: variantId
          };
          const personPostUrl = urls.api.personsByVariantId(variantId);
          const personPutUrlFunc = urls.api.person;

          FormItemsComponent = withCreatableDropdown(
            FormItemsComponent,
            "person",
            personPostUrl,
            personPutUrlFunc,
            PERSON_FORM_FIELD_NAMES,
            personNonFieldData
          );
        }
      }

      if (includedFields.includes("connection") && typeof siteId === "number") {
        const connectionNonFieldData = {
          site: siteId,
          sketchElement: { x: 0, y: 0 }
        };
        const connectionPostUrl = urls.api.connections(siteId);
        const connectionPutUrlFunc = urls.api.connection;

        FormItemsComponent = withCreatableDropdown(
          FormItemsComponent,
          "connection",
          connectionPostUrl,
          connectionPutUrlFunc,
          siteIsMieterstrom
            ? FIELDS_FOR_MIETERSTROM_SITES
            : DEFAULT_CONNECTION_FIELDS,
          connectionNonFieldData
        );
      }

      if (
        includedFields.includes("meteringLocation") &&
        typeof siteId === "number"
      ) {
        const meteringLocationNonFieldData = {
          site: siteId
        };
        const meteringLocationPostUrl = urls.api.meteringLocations(siteId);
        const meteringLocationPutUrlFunc = urls.api.meteringLocation;

        FormItemsComponent = withCreatableDropdown(
          FormItemsComponent,
          "meteringLocation",
          meteringLocationPostUrl,
          meteringLocationPutUrlFunc,
          formFieldNames(meterlingLocationFormFieldNames),
          meteringLocationNonFieldData
        );
      }

      if (
        includedFields.includes("marketLocationFeedin") &&
        typeof siteId === "number"
      ) {
        const marketLocationNonFieldData = {
          site: siteId
        };
        const marketLocationPostUrl = urls.api.marketLocations(siteId);
        const marketLocationPutUrlFunc = urls.api.marketLocation;

        FormItemsComponent = withCreatableDropdown(
          FormItemsComponent,
          "marketLocationFeedin",
          marketLocationPostUrl,
          marketLocationPutUrlFunc,
          formFieldNames(marketLocationFormFieldNames),
          marketLocationNonFieldData
        );
      }

      if (
        includedFields.includes("marketLocationFeedout") &&
        typeof siteId === "number"
      ) {
        const marketLocationNonFieldData = {
          site: siteId
        };
        const marketLocationPostUrl = urls.api.marketLocations(siteId);
        const marketLocationPutUrlFunc = urls.api.marketLocation;

        FormItemsComponent = withCreatableDropdown(
          FormItemsComponent,
          "marketLocationFeedout",
          marketLocationPostUrl,
          marketLocationPutUrlFunc,
          formFieldNames(marketLocationFormFieldNames),
          marketLocationNonFieldData
        );
      }

      if (
        includedFields.includes("connectingParty") &&
        typeof variantId === "number"
      ) {
        const connectingPartyNonFieldData = {
          variant: variantId
        };
        const connectingPartyPostUrl = urls.api.personsByVariantId(variantId);
        const connectingPartyPutUrlFunc = urls.api.person;

        FormItemsComponent = withCreatableDropdown(
          FormItemsComponent,
          "connectingParty",
          connectingPartyPostUrl,
          connectingPartyPutUrlFunc,
          PERSON_FORM_FIELD_NAMES,
          connectingPartyNonFieldData
        );
      }

      if (
        includedFields.includes("supplyContractHeldBy") &&
        typeof variantId === "number"
      ) {
        const personNonFieldData = {
          variant: variantId
        };
        const personPostUrl = urls.api.personsByVariantId(variantId);
        const personPutUrlFunc = urls.api.person;

        FormItemsComponent = withCreatableDropdown(
          FormItemsComponent,
          "supplyContractHeldBy",
          personPostUrl,
          personPutUrlFunc,
          PERSON_FORM_FIELD_NAMES,
          personNonFieldData
        );
      }

      if (includedFields.includes("invoiceSupplierLogo")) {
        FormItemsComponent = withCombinedFields(
          FormItemsComponent,
          UploadableImageField,
          ["invoiceSupplierLogo"],
          {
            extraProps: {
              resourceImageFieldName: "invoice_supplier_logo",
              resourceId: extraProps.personId,
              uploadUrl: urls.api.uploadPersonImage,
              deleteUrl: urls.api.deletePersonImage,
              imageType: "image"
            }
          }
        );
      }

      if (includedFields.includes("taxFormsApplicantStreet")) {
        FormItemsComponent = withCombinedFields(
          FormItemsComponent,
          AddressFields,
          ["taxFormsApplicantStreet", "taxFormsApplicantHouseNumber"]
        );
      }

      if (
        includedFields.includes(
          GENERATOR_STROMSTEUER_FIELDS.STROMSTG_PARAGRAPH_9_1_3_A
        )
      ) {
        FormItemsComponent = withDependentDaterange(
          FormItemsComponent,
          GENERATOR_STROMSTEUER_FIELDS.STROMSTG_PARAGRAPH_9_1_3_A,
          GENERATOR_STROMSTEUER_FIELDS.ZEITRAUM_STROMSTG_PARAGRAPH_9_1_3_A
        );
      }

      if (
        includedFields.includes(
          GENERATOR_STROMSTEUER_FIELDS.STROMSTG_PARAGRAPH_9_1_3_B
        )
      ) {
        FormItemsComponent = withDependentDaterange(
          FormItemsComponent,
          GENERATOR_STROMSTEUER_FIELDS.STROMSTG_PARAGRAPH_9_1_3_B,
          GENERATOR_STROMSTEUER_FIELDS.ZEITRAUM_STROMSTG_PARAGRAPH_9_1_3_B
        );
      }

      if (
        includedFields.includes(
          STROMSTEUER_FIELDS_ABOVE_TABLE.HAS_STANDORTUEBERGREIFENDE_VERKLAMMERUNG
        )
      ) {
        FormItemsComponent = withCombinedFields(
          FormItemsComponent,
          RightAlignedField,
          [
            STROMSTEUER_FIELDS_ABOVE_TABLE.HAS_STANDORTUEBERGREIFENDE_VERKLAMMERUNG
          ]
        );
      }

      if (includedFields.includes("taxFormsCompanyRepresentedBy")) {
        FormItemsComponent = withCombinedFields(
          FormItemsComponent,
          RepresentationFields,
          [
            "taxFormsSubmissionInOwnName",
            "taxFormsCompanyRepresentedBy",
            "taxFormsRepresentationType"
          ],
          {
            extraProps: {
              missingFields: missingFields,
              variantId: extraProps.variantId,
              personId: extraProps.personId
            }
          }
        );
      }

      if (
        includedFields.includes(GENERAL_FIELDS_PART_2.APPLICANT_LEGAL_STRUCTURE)
      ) {
        FormItemsComponent = withCombinedFields(
          FormItemsComponent,
          GeneralPartnerFields,
          Object.values(GENERAL_PARTNER_FIELDS)
        );
      }
    }

    const FormItemsComponentWithExtraProps = (props) => (
      <FormItemsComponent {...extraProps} {...props} />
    );

    return FormItemsComponentWithExtraProps;
    //add `isHighlighted` back into dependency array when it is properly
    //stabilized as part of https://node-energy.atlassian.net/browse/UIUX-96
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exclusiveOrFields, extraProps, fields, missingFields]);

  if (!CustomFormItemsComponent) {
    return null;
  }

  const fieldsWithValues = fields.filter((field) =>
    Object.prototype.hasOwnProperty.call(values, field.name)
  );

  if (fieldsWithValues.length === 0) {
    return null;
  }

  return (
    <>
      {isCollapsible ? (
        <>
          {header && (
            <div
              className="collapse-header-container"
              onClick={() => setIsOpen(!isOpen)}
            >
              <Icon
                className="arrow-container"
                name={isOpen ? IconName.AngleDown : IconName.AngleRight}
              />
              <h6
                className={
                  isSubSection
                    ? "collapse-sub-section-header"
                    : "collapse-section-header"
                }
              >
                {header}
              </h6>
            </div>
          )}
          <Collapse isOpen={isOpen}>
            <CustomFormItemsComponent
              allowInput
              exclusiveOrFields={exclusiveOrFields}
              formErrors={errors}
              formItems={fieldsWithValues}
              formName={name}
              formValues={values}
              isHighlighted={isHighlighted}
              missingFields={missingFields}
              onInput={onInput}
              onSubmit={() => {
                //
              }}
            />
          </Collapse>
        </>
      ) : (
        <>
          {header && (
            <div className="component-edit-wizard-header-container">
              <h6
                className={
                  isSubSection ? "sub-section-header" : "section-header"
                }
              >
                {header}
              </h6>
              {helpText && <IconHelpText helpText={helpText} />}
            </div>
          )}
          <CustomFormItemsComponent
            allowInput
            exclusiveOrFields={exclusiveOrFields}
            formErrors={errors}
            formItems={fieldsWithValues}
            formName={name}
            formValues={values}
            isHighlighted={isHighlighted}
            missingFields={missingFields}
            onInput={onInput}
            onSubmit={() => {
              //
            }}
          />
        </>
      )}
    </>
  );
}

export { Section };
