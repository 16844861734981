import { useQueryClient } from "@tanstack/react-query";
import PropTypes from "prop-types";
import { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useConfiguratorComponents } from "../../../../hooks/useConfiguratorComponents";
import { useSiteCategories } from "../../../../hooks/useSiteCategories";
import { useVariantSite } from "../../../../hooks/useVariantSite";
import type { components } from "../../../../types/api.types";
import urls from "../../../../urls";
import { EDIT_STEPS } from "../../../../utils/constants";
import {
  OBJECT_URL_NAMES_TO_OBJECT_NAMES,
  ObjectName,
  Product
} from "../../../../utils/enums";
import { getPluralVariableNameFromObjectName } from "../../../../utils/getPluralVariableNameFromObjectName";
import { showToast } from "../../../../utils/toast";
import { ErrorAlert } from "../../../BuildingBlocks/ErrorAlert/ErrorAlert";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../../../BuildingBlocks/Layout/Modal/Modal";
import { Button } from "../../../Buttons/Button/Button";
import { DynamicFormWithLoader } from "../../../DynamicForm/DynamicForm";
import { openErrorAlertPopup } from "../../../ErrorAlertPopup/openErrorAlertPopup";
import { AnimatedLoadingIcon } from "../../../Icons/AnimatedLoadingIcon/AnimatedLoadingIcon";
import { useShouldShowStaffView } from "../../../StaffViewToggle/useShouldShowStaffView";
import "./ComponentEditWizard.scss";
import { FormAction } from "./ComponentEditWizard.types";
import { loadConnectionData, saveConnectionData } from "./Data/Connection";
import { loadConsumerData, saveConsumerData } from "./Data/Consumer";
import {
  loadGasConnectionData,
  saveGasConnectionData
} from "./Data/GasConnection";
import { loadGeneratorData, saveGeneratorData } from "./Data/Generator";
import {
  loadMeterData,
  saveMeterData,
  savePremiumMeterData
} from "./Data/Meter";
import { loadPersonData, savePersonData } from "./Data/Person";
import { loadStorageData, saveStorageData } from "./Data/Storage";
import { EditForms } from "./EditForms/EditForms";

const EDIT_FORM_SAVE_FUNCTIONS = {
  [ObjectName.Generator]: saveGeneratorData,
  [ObjectName.Consumer]: saveConsumerData,
  [ObjectName.Storage]: saveStorageData,
  [ObjectName.Person]: savePersonData,
  [ObjectName.Connection]: saveConnectionData,
  [ObjectName.Meter]: saveMeterData,
  [ObjectName.GasConnection]: saveGasConnectionData
};

const RULES_TRIGGER_API = {
  [ObjectName.Generator]: urls.api.triggerGeneratorRules,
  [ObjectName.Consumer]: urls.api.triggerConsumerRules,
  [ObjectName.Storage]: urls.api.triggerStorageRules
};

const COMPONENTS_TRIGGERING_BUSINESS_RULES = [
  ObjectName.Generator,
  ObjectName.Consumer,
  ObjectName.Storage
];

function getTriggerRulesUrl(objectName, componentId) {
  return RULES_TRIGGER_API[objectName](componentId);
}

function formsReducer(state, action) {
  switch (action.type) {
    case FormAction.SetForms:
      return action.payload;
    case FormAction.UpdateCustomComponentData: {
      const formsWithUpdatedData = [...state];
      const formWithUpdatedData = {
        ...formsWithUpdatedData[action.payload.formIndex],
        data: action.payload.data
      };

      formsWithUpdatedData[action.payload.formIndex] = formWithUpdatedData;

      return formsWithUpdatedData;
    }
    case FormAction.UpdateValue: {
      const formsWithUpdatedValue = [...state];
      const formWithUpdatedValue = {
        ...formsWithUpdatedValue[action.payload.formIndex],
        sections: [...formsWithUpdatedValue[action.payload.formIndex].sections]
      };
      const sectionWithUpdatedValue = {
        ...formWithUpdatedValue.sections[action.payload.sectionIndex],
        values: {
          ...formWithUpdatedValue.sections[action.payload.sectionIndex].values,
          ...action.payload.valueKeyValuePair
        }
      };

      formWithUpdatedValue.sections[action.payload.sectionIndex] =
        sectionWithUpdatedValue;
      formsWithUpdatedValue[action.payload.formIndex] = formWithUpdatedValue;

      return formsWithUpdatedValue;
    }
    default:
      return state;
  }
}

interface ComponentEditWizardProps {
  product: Product;
  siteId?: number;
  variantId: number;
  doAfterSave?: () => void;
  isSubSection?: boolean;
  isCollapsible?: boolean;
  initialToggle?: boolean;
  componentId?: number;
  componentName?: string;
  currentUserCanAssessManagerSiteFinancially: boolean;
  objectName?: string;
  isOpen: boolean;
  onClose: () => void;
  helpText?: string;
}

function ComponentEditWizard({
  componentId,
  objectName,
  componentName,
  siteId,
  variantId,
  product,
  currentUserCanAssessManagerSiteFinancially,
  isOpen,
  doAfterSave,
  onClose
}: ComponentEditWizardProps) {
  const isStaff = useShouldShowStaffView();
  const queryClient = useQueryClient();
  const [forms, formsDispatch] = useReducer(formsReducer, []);
  const [editFormSubmitting, setEditFormSubmitting] = useState(false);
  const [step, setStep] = useState(EDIT_STEPS.EDIT_FORM);
  const [error, setError] = useState(null);
  const [footerNode, setFooterNode] = useState(null);

  const footerRef = useCallback((node) => {
    if (node !== null) {
      setFooterNode(node);
    }
  }, []);

  const innerModalBodyRef = useRef<HTMLDivElement>(null);
  const scrollToTop = () => {
    if (innerModalBodyRef.current) {
      innerModalBodyRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start"
      });
    }
  };
  const shouldFetchSiteCategories =
    (objectName === ObjectName.Person ||
      objectName === ObjectName.Generator ||
      objectName === ObjectName.Consumer ||
      objectName === ObjectName.Connection) &&
    product === Product.Manager;
  const { siteId: variantSiteId } = useVariantSite(
    variantId,
    !shouldFetchSiteCategories || !variantId
  );
  const { projectId } = useParams();
  const { siteCategories } = useSiteCategories(projectId ?? null, {
    enabled: shouldFetchSiteCategories && !!projectId
  });

  useEffect(() => {
    let loadPromise;
    const isPremiumSite =
      !!siteCategories &&
      siteCategories.some((site) => site.id === siteId && site.is_premium);
    let isEEPlus = false;
    let isMieterstromSite = false;

    switch (objectName) {
      case ObjectName.Generator:
        loadPromise = loadGeneratorData(
          queryClient,
          componentId,
          siteId,
          variantId,
          product,
          currentUserCanAssessManagerSiteFinancially,
          isPremiumSite
        );

        break;
      case ObjectName.Consumer:
        if (shouldFetchSiteCategories && siteCategories) {
          const siteCategoriesForSite = siteCategories.find(
            (siteCategoriesForSite) => siteCategoriesForSite.id === siteId
          );
          isEEPlus = siteCategoriesForSite?.is_eigenerzeugung_plus ?? false;
        }
        if (!siteId || !componentId) break;
        loadPromise = loadConsumerData(
          queryClient,
          componentId,
          siteId,
          variantId,
          product,
          currentUserCanAssessManagerSiteFinancially,
          isEEPlus
        );
        break;
      case ObjectName.Storage:
        if (!siteId || !componentId) break;
        loadPromise = loadStorageData(
          queryClient,
          componentId,
          siteId,
          variantId,
          product,
          currentUserCanAssessManagerSiteFinancially
        );
        break;
      case ObjectName.Person:
        if (!shouldFetchSiteCategories || siteCategories) {
          const siteCategoriesUnion = siteCategories?.reduce<
            components["schemas"]["SiteCategories"] | undefined
          >((union, siteCategoriesForSite) => {
            if (!union) {
              return {
                ...siteCategoriesForSite
              };
            }

            return {
              is_eigenerzeugung_plus:
                union.is_eigenerzeugung_plus ||
                siteCategoriesForSite.is_eigenerzeugung_plus,
              is_full_feedin:
                union.is_full_feedin || siteCategoriesForSite.is_full_feedin,
              is_premium: union.is_premium || siteCategoriesForSite.is_premium,
              is_partial_feedin_site:
                union.is_partial_feedin_site ||
                siteCategoriesForSite.is_partial_feedin_site,
              is_ppaaas: union.is_ppaaas || siteCategoriesForSite.is_ppaaas
            };
          }, undefined);
          if (!componentId) break;

          if (!shouldFetchSiteCategories) {
            loadPromise = loadPersonData(
              queryClient,
              componentId,
              variantId,
              product
            );
          } else if (siteCategoriesUnion) {
            loadPromise = loadPersonData(
              queryClient,
              componentId,
              variantId,
              product,
              siteCategoriesUnion
            );
          }
        }
        break;
      case ObjectName.Connection:
        if (!siteId || !componentId) break;
        isMieterstromSite =
          siteCategories?.some(
            (site) =>
              site.id === siteId &&
              site.is_partial_feedin_site &&
              !site.is_eigenerzeugung_plus
          ) ?? false;
        loadPromise = loadConnectionData(
          queryClient,
          componentId,
          siteId,
          variantId,
          isMieterstromSite
        );
        break;
      case ObjectName.Meter:
        if (!siteId || !componentId) break;
        loadPromise = loadMeterData(
          queryClient,
          componentId,
          siteId,
          variantId,
          isPremiumSite,
          isStaff
        );
        break;
      case ObjectName.GasConnection:
        loadPromise = loadGasConnectionData(
          queryClient,
          componentId,
          siteId,
          variantId
        );
        break;
      default:
        console.error("Unexpected object name error!");
        break;
    }

    if (!loadPromise) {
      return;
    }

    Promise.resolve(loadPromise)
      .then((data) => {
        formsDispatch({ type: FormAction.SetForms, payload: data });
      })
      .catch((error) => {
        setStep(EDIT_STEPS.ERROR);
        setError(error);
      });
  }, [
    objectName,
    componentId,
    isStaff,
    siteId,
    variantId,
    product,
    currentUserCanAssessManagerSiteFinancially,
    shouldFetchSiteCategories,
    siteCategories,
    variantSiteId,
    projectId,
    queryClient
  ]);

  function handleUpdateComponent() {
    setEditFormSubmitting(true);

    const isPremiumSite =
      !!siteCategories &&
      siteCategories.some((site) => site.id === siteId && site.is_premium);
    let saveFunction;

    if (!objectName) return;

    if (objectName === ObjectName.Meter && isPremiumSite) {
      saveFunction = savePremiumMeterData;
    } else {
      saveFunction = EDIT_FORM_SAVE_FUNCTIONS[objectName];
    }

    saveFunction(componentId, forms, product).then((result) => {
      formsDispatch({ type: FormAction.SetForms, payload: result.forms });
      setEditFormSubmitting(false);
      if (result.didError) {
        if (result.serverError) {
          openErrorAlertPopup(result.serverError);
        } else {
          scrollToTop();
        }
      } else {
        if (
          COMPONENTS_TRIGGERING_BUSINESS_RULES.includes(
            objectName as ObjectName
          )
        ) {
          triggerBusinessRules();
        } else {
          onClose();
          showToast("success", "Die Änderungen wurden gespeichert.");
        }

        const objectCacheName = getPluralVariableNameFromObjectName(
          objectName as ObjectName
        );

        if (objectName === ObjectName.Person) {
          queryClient.invalidateQueries({
            queryKey: [objectCacheName, { siteOrVariantId: variantId }]
          });
        } else {
          queryClient.invalidateQueries({
            queryKey: [objectCacheName, { siteOrVariantId: siteId }]
          });
        }
      }
      if (doAfterSave) {
        doAfterSave();
      }
    });
  }

  function triggerBusinessRules() {
    setStep(EDIT_STEPS.BUSINESS_RULES_FORM);
  }

  function handleBusinessRulesStepDone() {
    onClose();
    showToast("success", "Die Änderungen wurden gespeichert.");
  }

  function renderForms() {
    if (error) {
      console.error(error);
      return <ErrorAlert error={error} />;
    }

    if (forms.length === 0) {
      return <AnimatedLoadingIcon />;
    }

    switch (step) {
      case EDIT_STEPS.EDIT_FORM:
        return (
          <EditForms
            buttonContainer={footerNode}
            forms={forms}
            formsDispatch={formsDispatch}
            submitting={editFormSubmitting}
            onClickSubmit={handleUpdateComponent}
            onClose={onClose}
          />
        );
      case EDIT_STEPS.BUSINESS_RULES_FORM: {
        const businessRuleTriggerUrl = getTriggerRulesUrl(
          objectName,
          componentId
        );
        return (
          <DynamicFormWithLoader
            buttonContainer={footerNode}
            dataUrls={[businessRuleTriggerUrl]}
            doWhenTaskSuccessfulThenAbortLoading={handleBusinessRulesStepDone}
            onDone={handleBusinessRulesStepDone}
          />
        );
      }
      default:
        console.log("Unexpected step error!");
        return null;
    }
  }

  return (
    <Modal isOpen={isOpen} size="lg" toggle={onClose}>
      <ModalHeader toggle={onClose}>{componentName} bearbeiten</ModalHeader>
      <ModalBody scrollable>
        <div ref={innerModalBodyRef}>{renderForms()}</div>
      </ModalBody>
      <ModalFooter>
        <div className="component-edit-wizard-buttons" ref={footerRef} />
      </ModalFooter>
    </Modal>
  );
}

ComponentEditWizard.propTypes = {
  componentId: PropTypes.number.isRequired,
  componentName: PropTypes.string.isRequired,
  siteId: PropTypes.number,
  variantId: PropTypes.number.isRequired,
  currentUserCanAssessManagerSiteFinancially: PropTypes.bool.isRequired,
  objectName: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  helpText: PropTypes.string
};

function ComponentEditWizardRouteWrapper({
  product,
  siteId,
  variantId,
  doAfterSave,
  isOpen,
  onClose,
  currentUserCanAssessManagerSiteFinancially,
  ...props
}: ComponentEditWizardProps) {
  const { componentId, companyId, objectUrlName, projectPage } = useParams();
  const componentOrCompanyId =
    componentId || companyId
      ? parseInt((componentId ?? companyId) as string, 10)
      : undefined;
  const objectName =
    OBJECT_URL_NAMES_TO_OBJECT_NAMES[objectUrlName ?? projectPage ?? ""];
  const siteOrVariantId = objectName === ObjectName.Person ? variantId : siteId;
  const { data: configuratorComponents } = useConfiguratorComponents(
    objectName,
    siteOrVariantId
  );

  if (!configuratorComponents || configuratorComponents.length === 0) {
    return null;
  }

  const component = configuratorComponents.find(
    (object) => object.id === componentOrCompanyId
  );

  if (!component) {
    return <NotFound isOpen={isOpen} onClose={onClose} />;
  }

  return (
    <ComponentEditWizard
      componentId={componentOrCompanyId}
      componentName={component.name}
      currentUserCanAssessManagerSiteFinancially={
        currentUserCanAssessManagerSiteFinancially
      }
      doAfterSave={doAfterSave}
      isOpen={isOpen}
      objectName={objectName}
      product={product}
      siteId={siteId}
      variantId={variantId}
      onClose={onClose}
      {...props}
    />
  );
}

function NotFound({
  isOpen,
  onClose
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} size="lg" toggle={onClose}>
      <ModalHeader toggle={onClose}>Nicht gefunden</ModalHeader>
      <ModalBody scrollable>
        <div>{t("errors.NotFoundMaybeDeleted")}</div>
      </ModalBody>
      <ModalFooter>
        <Button color="brand" onClick={onClose}>
          Schließen
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export { ComponentEditWizardRouteWrapper as ComponentEditWizard, EditForms };
