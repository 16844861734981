import { Stack, Text } from "@mantine/core";
import { SiteName } from "../../utils/enums";

export interface FooterProps {
  siteName: SiteName;
}

const getCopyright = (siteName: SiteName) => {
  switch (siteName) {
    case SiteName.Optinode:
      return "node.energy GmbH";
    case SiteName.Smarendo:
      return "Rödl & Partner GmbH";
    case SiteName.Alva:
      return "Alva Energie GmbH";
    default:
      return "";
  }
};

function Footer({ siteName }: FooterProps) {
  return (
    <Stack
      component="footer"
      h={60}
      justify="center"
      pl="30px"
      style={{ boxShadow: "var(--mantine-shadow-md)" }}
    >
      <Text c="dimmed" fw={400} fz="xs">
        {new Date().getFullYear()} &copy; {getCopyright(siteName)}
      </Text>
    </Stack>
  );
}

export { Footer };
